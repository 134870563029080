import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  public baseUrl = environment.baseBtlUrl;
  public balanceData: any = [];
  public isTableVisibile: boolean = false;
  public toggleTableViewVisibilityChange: Subject<boolean> = new Subject<boolean>();
  public title: string = 'Deposit Accounts';
  public ssoIdArr: any = [];
  private _applicationName: string = '';

  constructor(private http: HttpClient) { }

  public setViewToggleValue(value: boolean){
    this.isTableVisibile = value
  }

  public toggleTableViewVisibility() {
    this.toggleTableViewVisibilityChange.next(!this.isTableVisibile)
  }

  public getViewToggleValue(){
    return this.isTableVisibile
  }

  public setSsoIdArrValue(value: any){
    this.ssoIdArr = value
  }

  public getSsoIdArrValue() {
    return this.ssoIdArr
  }

  public getFunction(url: string, data: any) {
    return this.http.get(`${this.baseUrl}/${url}`, { headers: data }).pipe(map((response: any) => response),catchError((error: any)=>{
      return of (error)
    }))
  }

  public getViewFunction(url: string, data: any) {
    return this.http.get(`${this.baseUrl}/${url}?accountsList=${data.accountsList}`, { headers: {SSO_ID: data['SSO_ID']} }).pipe(map((response: any) => response))
  }

  public postFunction(url: string, reqBody: any, SSO_ID: string) {
    return this.http.post(`${this.baseUrl}/${url}`, reqBody, {
      headers: { SSO_ID }
    }).pipe(map((response: any) => response), catchError((error: any) => {
      return of(error)
    }));
  }

  set appName(name: string) {
    this._applicationName = name;
  }

  get appName(): string {
    return this._applicationName;
  }
}
