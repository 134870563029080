import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis'
})
export class TextEllipsisPipe implements PipeTransform {

  transform(value: string) {
    if (value.length > 15) {
      return value.substring(0, 16) + '...';
    } else {
      return value
    }
  }
}
