import { Component, Input } from '@angular/core';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';

@Component({
  selector: 'app-loan-accounts',
  templateUrl: './loan-accounts.component.html',
  styleUrls: ['./loan-accounts.component.css']
})
export class LoanAccountsComponent {
  @Input() ssoId: 'string'
  public loanAccountsData: any = [];
  public viewAll: boolean = false;
  public showTileView: boolean = true;
  public totalAvailableBalance: string | number = '';
  public displaySpinner: boolean = false;
  public errorMessage: string;
  public isError: boolean = false;

  // table pages
  totalTablePages: number = 1;

  firstThreeAcc: any = [];
  restAcc: any = [];

  // Material Pagination
  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  public resizedWindow: boolean = false;
  public spinnerTitle: string = 'Loading Loan Accounts...';
  constructor(private appService: ApplicationService, protected modalService: ModalService,
    private spinnerService: SpinnerService
  ) {
    this.resizedWindow = this.modalService.setWindow();
  }

  ngOnInit(): void {
    this.getLoanData({ pageIndex: 0, pageSize: 10 });
  }

  get isTableVisible() {
    if (this.appService.getViewToggleValue()) {
      this.showTileView = false
    }
    return this.appService.getViewToggleValue()
  }

  public getLoanData: any = (pageInfo: any) => {
    this.appService.getFunction(
      `v1/accounts/LOANACCTS?pageOffset=${((pageInfo['pageIndex']) * pageInfo['pageSize']) + 1}&pageLimit=${pageInfo['pageSize']}`,
      { 'SSO_ID': `${this.ssoId}` }
    ).subscribe((response) => {
      this.handleResponse(response)
    }, (error) => {
      this.isError = true;
      this.displaySpinner = false;
      this.errorMessage = `Unable to display loan accounts, please check the balances in ${this.appService.appName}`;
      console.error('Error fetching loan data:', error);
    })
  }

  public handleResponse(response: any) {
    if(response.status === 200) {      
      this.loanAccountsData = response.data;
      this.totalTablePages = this.loanAccountsData.totalPages;
      this.totalAvailableBalance = this.calculateTotalAvailableBalance(this.loanAccountsData.accountSummaries)
      this.splitAccountSummaryData(this.loanAccountsData.accountSummaries)
      this.checkTileViewValue()
    } 
  }

  public calculateTotalAvailableBalance(accountSummaries: any[]): number {
    return accountSummaries.reduce((b: any, a: any) => b + a.availableBalance, 0);
  }

  public splitAccountSummaryData(accountSummaries: any[]){
    this.firstThreeAcc = accountSummaries.slice(0, 3);
    this.restAcc = accountSummaries.slice(3);
  }

  public getTransactionList: any = (accountInfo: any) => {
    this.spinnerService.setText('Please wait...');
    this.spinnerService.showSpinner();
    const accountDetails = {
      clientName: accountInfo.clientAccountName,
      accountName: accountInfo.accountName,
      accountNumber: accountInfo.accountNumber,
      accountFilter: accountInfo.accountFilter,
      availableBalance: accountInfo.availableBalance,
      principalBalance: accountInfo.principalBalance,
      ssoId: this.ssoId
    }

    if (!accountInfo.isRealTimeAccount) {
      this.openViewMore(accountDetails);
      return;
    }

    this.modalService.setAccountValues(accountDetails, 'loans')
    this.modalService.setWindow()
    this.modalService.openDialog().then(() => {
      this.spinnerService.hideSpinner();
    }).catch(() => {
      this.spinnerService.hideSpinner();
    })
  }

  public checkViewAll: any = () => {
    this.viewAll = !this.viewAll
    this.checkTileViewValue()
  }

  public checkTileViewValue: any = () => {
    this.showTileView = this.loanAccountsData.totalRows > 12 ? false : true
  }

  handleTablePagination(actionType: any) {
    this.getLoanData(actionType);
  }

  openViewMore(accountDetail: any) {
    const getSsoIdArr = this.appService.getSsoIdArrValue()
    const selectSsoId = getSsoIdArr.find((ssoIdData: any) => ssoIdData['ssoId'] === accountDetail['ssoId'])

    const openAOEvent = new CustomEvent("open-access-optima", {
      detail: { from: "btl-application", ssoIdData: selectSsoId }
    });
    dispatchEvent(openAOEvent);
  }

}